<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'商品'"
      :isInline="true"
    >
      <div slot="toolSub"></div>
      <div class="search" slot="tool">
        <el-input
          placeholder="按用户名查询"
          v-model="searchQuery.jobCode"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">用户名</template>
        </el-input>

        <el-input
          placeholder="按IP查询"
          v-model="searchQuery.jobName"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">IP</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">状态</div>
          <el-select
            filterable
            v-model="searchQuery.status"
            clearable
            placeholder="按状态筛选"
            size="small"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      apiName: "/systemLoginLog",
      searchVal: "",
      statusList: [
        { label: "成功", value: 0 },
        { label: "失败", value: 1 },
      ],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { jobName: "", jobCode: "", status: "" },
      rules: {
        //传递给APPlist的表单验证
        jobCode: [
          {
            required: true,
            message: "请输入岗位编码",
            trigger: ["blur", "change"],
          },
        ],
        jobName: [
          {
            required: true,
            message: "请输入岗位名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "用户名",
          prop: "username",
          align: "center",
        },
        {
          label: "IP",
          prop: "ip",
          align: "center",
        },

        {
          label: "浏览器",
          prop: "browser",
          align: "center",
        },

        {
          label: "操作系统",
          prop: "operatingSystem",
          align: "center",
        },

        {
          label: "登录时间",
          prop: "createTime",
          align: "center",
        },

        {
          label: "状态",
          prop: "status",
          type:"colorText",
          align: "center",
          formatter: function(row) {
            const statusMap = { 0: {text:"成功",color:"#67C23A"}, 1: {text:"失败",color:"#F56C6C"} };
            return statusMap[row.status];
          },
        },
        {
          label: "登录提示",
          prop: "message",
          align: "center",
        },

        
      ],
      formProps: [],
    };
  },
  created() {},
  methods: {
    //同步默认商品到商户
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
